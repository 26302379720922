* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: auto;
  font-family: MicrosoftYaHei;
}

.l {
  float: left;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: normal;
}

i,
em {
  font-style: normal;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

/*公共头部*/
.header_Warp,
.header_welcome,
.header_search,
.header_nav,
.header_banner {
  min-width: 1200px;
}

/*一*/
.header_welcome {
  background: #EFEFEF;
}

.header_welcomeMain {
  width: 1200px;
  height: 38px;
  margin: 0 auto;
  line-height: 38px;
}

.header_welcomeMain p {
  font-size: 14px;
  color: #666666;
  float: left;
}

.header_welcomeMain div {
  float: right;
}

.header_welcomeMain img {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 6px;
  margin-top: 1px;
  vertical-align: middle;
}

.header_welcomeMain span {
  font-size: 14px;
  color: #000000;
}

/*search*/
.header_search {
  background: #FFFFFF;
}

.header_searchMain {
  width: 1200px;
  height: 106px;
  /* line-height: 106px; */
  margin: 0 auto;
  /* display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  align-items: center; */
  padding-top: 28px;
}

.header_searchMainLeft {
  /* display: flex;
  display: -ms-flexbox;
  align-items: center; */
  float: left;
  overflow: hidden;
  padding: 12px 0;
}
.header_searchMainLeft img{
  /* display: flex;
  display: -ms-flexbox;
  align-items: center; */
  float: left;
}

.header_searchMainLeft .headerIconf {
  display: block;
  width: 92px;
  height: 64px;
  cursor: pointer;
}

.header_searchMainLeft .headerWord {
  display: block;
  width: 201px;
  height: 32px;
  margin-left: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.header_searchMainRight {
  /* display: flex; */
  /* display: -ms-flexbox; */
  /* align-items: center; */
  /* overflow: hidden; */
  margin-left: 20px;
  padding: 12px 0;
  float: right;
}

.header_searchMainSearch {
  width: 510px;
  height: 50px;
  background: rgba(249, 249, 249, 1);
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 2px;
  float: left;
  /* display: flex;
  display: -ms-flexbox;
  align-items: center; */
}

.header_searchMainSearch .searchIconf {
  display: block;
  width: 10px;
  height: 5px;
  position: absolute;
  top: 25px;
  right: 12px;
}

.searchInput {
  /* display: flex; */
  /* display: -ms-flexbox;
  align-items: center; */
  float: right;
}

.SearchLine {
  display: inline-block;
  width: 2px;
  height: 18px;
  background: rgba(219, 219, 219, 1);
  padding-top: 16px;
}

.header_searchMainSearch .searchIconf1 {
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  float: right;
  vertical-align: middle;
  margin-right: 20px;
  margin-top: 16px;
}

#selectList {
  position: relative;
  cursor: pointer;
  float: left;
  width: 126px;
  text-align: center;
}

.SearchList {
  position: absolute;
  top: 50px;
  left: 0;
  background: #F9F9F9;
}

.SearchList li {
  width: 126px;
  height: 40px;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  text-align: center;
}

.SearchList li:hover {
  background: #EFEFEF;
}

.SearchWord {
  /* display: -ms-flexbox; */
  width: 250px;
  /* justify-content: center; */
  /* align-items: center; */
  height: 50px;
  line-height: 50px;
}

.SearchWord span {
  font-size: 16px;
}

.search {
  border: none;
  margin-left: 20px;
  background: rgba(249, 249, 249, 1);
  outline: none;
  font-size: 16px;
  width: 280px;
  margin-right: 20px;
  height: 48px;
}

.header_searchMainSearchR {
  float: right;
  /* margin-left: 33px; */
  /* display: flex; */
  /* display: -ms-flexbox; */
  padding: 12px 20px;
  /* align-items: center; */
 overflow: hidden;
}
.header_searchMainSearchR span{
float: left;
  /* display: flex; */
  /* display: -ms-flexbox; */
  /* align-items: center; */

}
.header_searchMainSearchR a{
float: left;
  /* display: flex; */
  /* display: -ms-flexbox; */
  /* align-items: center; */

}

.header_searchMainSearchR i {
  display: block;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
}

.header_searchMainSearchR .SearchLine {
  display: block;
  width: 2px;
  background: #DBDBDB;
  margin: 0 21px;
}

/*nav*/
.header_nav{
  width: 1200px;
  margin: 0 auto;
}
.navList {
  width: 100%;
  margin-top: 9px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  height: 64px;
  line-height: 60px;
  padding-left: 138px;
}

.navList li {
  height: 64px;
  margin-right: 142px;
}

.navList li:last-child {
  margin-right: 0;
}

.navList li a {
  font-size: 20px;
  color: #333333;
  text-decoration: none;
}

.navList .navListClick a {
  color: #018AE6;
}

.navList .navListClick {
  border-bottom: 4px solid #018AE6;
}

.navList li:hover {
  border-bottom: 4px solid #018AE6;
}

.navList li:hover a {
  color: #018AE6;
}

/*banner*/
.header_banner .imgOne {
  display: block;
  width: 100%;
  height: 610px;
}

.header_banner .imgtwo {
  display: block;
  width: 100%;
  height: 450px;
}

/*公共尾部*/
.footer_Warp,
.footer_Top,
.footer_Bot {
  min-width: 1200px;
  clear: both;
}

.footer_Top {
  background: #292E30;
}

.footer_Bot {
  background: #121212;
}

.footer_TopMain,
.footer_BotMain {
  width: 1200px;
  margin: 0 auto;
}

.footer_TopMain {
  height: 160px;
  display: flex;
  display: -ms-flexbox;
  padding-top: 28px;
}

.footer_BotMain {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
}

.footerIconf {
  display: block;
  margin-right: 122px;
  margin-left: 25px;
  width: 150px;
  height: 104px;
}

.footerLine {
  width: 2px;
  height: 82px;
  background: rgba(255, 255, 255, .4);
}

.footerListOne,
.footerListTwo {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 32px;
  margin-top: -4px;
}

.footerListOne {
  margin-left: 130px;
  margin-right: 115px;
}

.footerListTwo {
  margin-left: 115px;
}

.footer_TopMain li {
  color: #FFFFFF;
}

[v-cloak] {
  display: none;
}

.selectCity {
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.w250 {
  width: 650px;
}

.w2 {
  width: 200px
}

.subject {
  margin-bottom: 20px;
}

.f18 :first-child {
  font-size: 17px;
}
